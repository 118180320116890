import React, { Component } from "react"
import Slider from "react-slick"
import Image from "../image"

export default class HistorySlider extends Component {
  render() {
    const { data, cssClass } = this.props

    var settings = {
      // dots: true,
      arrows: true,
      // fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: false,
      autoplaySpeed: 7000,
      speed: 1000,
      swipeToSlide: true,
      pauseOnHover: false,
    }

    // console.log(data)

    return (
      <div className={cssClass}>
        <Slider slickGoTo="0" {...settings}>
          {data &&
            data.map((slide, index) => {
              slide.node = slide.image
              // let content = slide.text
              // console.log(slide)
              return (
                <div className="slide" key={index}>
                  <Image data={slide} />
                </div>
              )
            })}
        </Slider>
      </div>
    )
  }
}
