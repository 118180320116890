import { motion, useTransform, useViewportScroll } from "framer-motion"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import HistorySlider from "../components/ACF/SliderHistory"
import Image from "../components/image"
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"

const PageHistory = props => {
  // console.log(props.data.wpPage.seo)

  let {
    location,
    data: {
      wpPage: {
        title,
        content,
        featuredImage,
        acf_history: { items },
        seo,
      },
    },
  } = props

  // console.log(items)

  const { scrollYProgress } = useViewportScroll()

  const up = useTransform(scrollYProgress, [0, 0.1, 0.3], ["0%", "75%", "150%"])

  const down = useTransform(
    scrollYProgress,
    [0, 0.2, 0.5],
    ["0%", "-20%", "-30%"]
  )

  return (
    <Layout location={location} title={title}>
      <SEO bodyClasses="history" seo={seo} />
      <div className="container-fluid p-0">
        <div className="row no-gutters">
          <div className="col-12 headerimage">
            <motion.div style={{ top: 0, y: down }}>
              <Image data={featuredImage} />
            </motion.div>
            <div className="container caption">
              <div className="row">
                <div className="col-12">
                  <motion.div style={{ top: 0, y: up }}>
                    <h1>{content && parse(content)}</h1>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="heading two">{title}</h1>
          </div>
        </div>
      </div>
      <div className="container pl-5">
        <div className="row">
          <div className="col-md-12">
            {items &&
              items.map((item, index) => {
                // console.log(item.images)
                return (
                  <div className="row article" key={index}>
                    <div className="col-md-1 year">
                      <span>{item.year}</span>
                    </div>
                    <div className="col-md-5 image">
                      <HistorySlider
                        data={item.images}
                        cssClass="history-slider"
                      />
                    </div>
                    <div className="col-md-6 col-lg-4 copy">
                      <div>{parse(item.copy)}</div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageHistory

export const pageHistory = graphql`
  {
    wpPage(databaseId: { eq: 23 }) {
      id
      title
      content
      featuredImage {
        node {
          ...fragmentImage
        }
      }
      acf_history {
        items {
          year
          images {
            image {
              ...fragmentImage
            }
          }
          copy
        }
      }
      seo {
        ...WpYoastSEO
      }
    }
  }
`
